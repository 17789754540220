<template>
  <SpinnerLogo v-if="loading.list" />

  <div v-else>
    <div class="row">
      <div class="col-md-4">
        <h4 class="font-w600 mb-1"><i class="fad fa-file-chart-pie text-primary mr-2"></i>Reports</h4>
        <p class="mb-3 text-muted font-w500">Common reports for your data in Etainabl. Such as consumption, emission and data quality reports.</p>
        <div class="mb-4">
          <FormItem id="system-search" v-model="search.system" placeholder="Search..." class="mb-2" />
          <div v-if="groupedTemplates.system.length === 0" class="alert alert-warning py-2 px-3">No Templates</div>
          <VerticalPills v-else :items="groupedTemplates.system" item-key="_id" :groups="groups.system" alt>
            <template #item="{ item }">
              <a href="#" class="nav-link" :class="{ active: selectedTemplateId === item.id }" @click.prevent="onSelect(item)">
                <div class="d-flex align-items-center w-100">
                  <div class="mr-2">
                    <span class="fa-stack text-primary fa-xl" style="zoom: 0.85">
                      <i class="fa fa-stack-1x" :class="`${item.icon}`" style="--fa-stack-z-index: 2; top: 5px"></i>
                      <i class="fad fa-file fa-stack-2x" style="--fa-stack-z-index: 1"></i>
                    </span>
                  </div>
                  <div class="flex-grow-1">
                    <div class="mb-0">{{ item.title }}</div>
                    <div class="text-muted font-size-sm font-w500">{{ item.description }}</div>
                  </div>
                </div>
              </a>
            </template>
          </VerticalPills>
        </div>
        <router-link :to="{ name: 'reports-dynamic' }" class="btn btn-text-secondary btn-block font-w600"
          ><i class="fa mr-1 fa-file-chart-column" /> Create Dynamic Report</router-link
        >
      </div>
      <div class="col-md-4">
        <h4 class="font-w600 mb-1"><i class="fad fa-user text-secondary mr-2"></i>Your Templates</h4>
        <p class="mb-3 text-muted font-w500">
          Templates that you have created from reports and dynamic charts. These templates can also be scheduled.
        </p>
        <div class="mb-4">
          <FormItem id="user-search" v-model="search.user" placeholder="Search..." class="mb-2" />
          <div v-if="groupedTemplates.user.length === 0" class="alert alert-warning py-2 px-3">No Templates</div>
          <VerticalPills v-else :items="groupedTemplates.user" item-key="_id" :groups="groups.user" alt>
            <template #item="{ item }">
              <a href="#" class="nav-link" :class="{ active: selectedTemplateId === item.id }" @click.prevent="onSelect(item)">
                <div class="mb-0"><i class="fad fa-fw mr-1 text-secondary" :class="`${item.icon}`"></i> {{ item.title }}</div>
              </a>
            </template>
          </VerticalPills>
        </div>
      </div>
      <div class="col-md-4">
        <h4 class="font-w600 mb-1"><i class="fad fa-building text-info mr-2"></i>Company Templates</h4>
        <p class="mb-3 text-muted font-w500">Templates that have been created by others in your company and made available to you.</p>
        <div class="mb-4">
          <FormItem id="company-search" v-model="search.company" placeholder="Search..." class="mb-2" />
          <div v-if="groupedTemplates.company.length === 0" class="alert alert-warning py-2 px-3">No Templates</div>
          <VerticalPills v-else :items="groupedTemplates.company" item-key="_id" :groups="groups.company" alt>
            <template #item="{ item }">
              <a href="#" class="nav-link" :class="{ active: selectedTemplateId === item.id }" @click.prevent="onSelect(item)">
                <div class="mb-0"><i class="fad fa-fw mr-1 text-info" :class="`${item.icon}`"></i> {{ item.title }}</div>
              </a>
            </template>
          </VerticalPills>
        </div>
      </div>
    </div>
    <div class="pt-5 pb-5 mb-5">&nbsp;</div>
    <div class="pt-5 pb-5 mb-5">&nbsp;</div>
    <ActionBox v-if="selectedTemplateId">
      <template>
        <a href="#" @click.prevent="showActionBox = !showActionBox">
          <h5 class="text-center text-primary mb-2">Selected Report <i class="fa fa-chevron-down mr-2"></i></h5>
        </a>
        <div v-if="showActionBox" class="row justify-content-between align-items-between h-100">
          <div class="col-md-5">
            <p class="text-muted text-uppercase font-w600 mb-1">
              <i
                class="fa mr-1"
                :class="{ 'fa-file-chart-pie': selectedTemplate.access === 'system', 'fa-file': selectedTemplate.access !== 'system' }"
              ></i
              >{{ selectedTemplate.access === 'system' ? 'Report' : 'Template' }}
            </p>
            <h3 class="mb-1">{{ selectedTemplate.name }}</h3>
            <p class="text-muted font-w500 mb-3">{{ selectedTemplate.description }}</p>
            <p v-if="selectedTemplateOriginal" class="text-muted font-w500 mb-4">
              This template was created from report:
              <strong>{{ selectedTemplateOriginal.name }}</strong>
            </p>
            <p v-else-if="selectedTemplate.type === 'dynamic'" class="text-muted font-w500 mb-4">This template was created from a dynamic report.</p>
            <TextListItem v-if="selectedTemplate.access === 'company'" icon="fa-user" label="Created By"
              >{{ selectedTemplate.userSub | user(users) }}
            </TextListItem>
          </div>
          <div class="col-md-7 pl-4 align-self-end">
            <div class="text-right">
              <button class="btn btn-primary mr-2" @click="onClickUseTemplate"><i class="fa fa-cog mr-2"></i> Create Report</button>
              <button v-if="selectedTemplate.access !== 'system'" class="btn btn-text-secondary mr-2" @click="onClickSchedule">
                <i class="fa fa-calendar-clock mr-2"></i> Schedule
              </button>
              <button v-if="selectedTemplate.access !== 'system'" class="btn btn-text-secondary mr-2" @click="onClickDuplicate">
                <i class="fa fa-copy mr-2"></i> Duplicate
              </button>
              <button v-if="selectedTemplate.userSub === $auth.user.sub" class="btn btn-text-danger" @click="modals.delete = selectedTemplate">
                <i class="fa fa-trash mr-2"></i>Delete
              </button>
            </div>
          </div>
        </div>
      </template>
    </ActionBox>
    <Modal :open="!!modals.delete" title="Delete Template" @close="modals.delete = false" @submit="onDeleteTemplate">
      <p>
        Are you sure you want to delete template <strong>{{ modals.delete.name }}</strong
        >?
      </p>
    </Modal>
    <Modal
      id="schedule"
      :open="modals.schedule.show"
      title="Schedule Report"
      text="Send out this report to users on a regular or one-off basis. You can edit scheduled reports at any time by going to the Reports > Schedule tab once created."
      lg-size
      :loading="modals.schedule.loading"
      @close="modals.schedule.show = false"
      @submit="onScheduleReport"
    >
      <ScheduledReportForm v-if="modals.schedule.show" v-model="modals.schedule.form" />
    </Modal>
  </div>
</template>
<script>
import { uniqBy } from 'lodash';
import { rawUnitOptions, formatOptions } from '../options';
import { mapActions, mapGetters } from 'vuex';

import ActionBox from '@/components/base/ActionBox';
import FormItem from '@/components/FormItem';
import Modal from '@/components/ConfirmModal';
import TextListItem from '@/components/base/TextListItem';
import SpinnerLogo from '@/components/SpinnerLogo';
import VerticalPills from '@/components/base/VerticalPills';
import ScheduledReportForm from '@/components/forms/ScheduledReportForm';

const initialScheduleForm = () => ({
  name: '',
  startDate: new Date(),
  reportPeriod: '1|month',
  frequency: '1|month',
  frequencyDay: 'any',
  frequencyPeriod: 'any',
  subject: '',
  message: '',
  recipients: [],
  enabled: true,
  reportTemplateId: null,
  overrides: {}
});

export default {
  name: 'ReportList',
  components: {
    ActionBox,
    FormItem,
    Modal,
    ScheduledReportForm,
    SpinnerLogo,
    TextListItem,
    VerticalPills
  },
  data() {
    return {
      selectedTemplateId: null,
      rawUnitOptions,
      formatOptions,
      modals: {
        schedule: {
          show: false,
          form: {},
          loading: false
        },
        delete: false,
        share: false
      },
      showActionBox: true,
      search: {
        system: '',
        company: '',
        user: ''
      }
    };
  },
  computed: {
    ...mapGetters({
      loading: 'reportTemplate/loadingAction',
      systemTemplates: 'reportTemplate/systemTemplates',
      templates: 'reportTemplate/reportTemplates',
      users: 'company/users'
    }),
    groups() {
      const templateMapping = item => ({
        title: item.category || 'Uncategorized',
        show: true,
        hideTitle: !item.category
      });

      const groups = {
        system: uniqBy(this.systemTemplates.map(templateMapping), 'title'),
        company: uniqBy(this.templates.filter(item => item.access === 'company').map(templateMapping), 'title'),
        user: uniqBy(this.templates.filter(item => item.access === 'user' && item.userSub === this.$auth.user.sub).map(templateMapping), 'title')
      };

      groups.system.sort((a, b) => a.title.localeCompare(b.title));
      groups.company.sort((a, b) => a.title.localeCompare(b.title));
      groups.user.sort((a, b) => a.title.localeCompare(b.title));

      return groups;
    },
    groupedTemplates() {
      const groupedTemplates = (templates, search) =>
        templates.reduce((acc, item) => {
          const category = item.category || 'Uncategorized';

          if (!acc[category]) acc[category] = [];

          if (!search || item.name.toLowerCase().includes(search.toLowerCase())) {
            acc[category].push({
              id: item._id,
              title: item.name,
              access: item.access,
              icon: item.icon || 'fa-file-lines',
              description: item.shortDescription || item.description
            });
          }

          return acc;
        }, {});

      const groups = {
        system: groupedTemplates(this.systemTemplates, this.search.system),
        company: groupedTemplates(
          this.templates.filter(item => item.access === 'company'),
          this.search.company
        ),
        user: groupedTemplates(
          this.templates.filter(item => item.access === 'user' && item.userSub === this.$auth.user.sub),
          this.search.user
        )
      };

      return groups;
    },
    selectedTemplate() {
      const selectedTemplate = [...this.systemTemplates, ...this.templates].find(item => item._id === this.selectedTemplateId);

      return selectedTemplate;
    },
    selectedTemplateOriginal() {
      return this.systemTemplates.find(item => item.subType === this.selectedTemplate.subType);
    }
  },
  async mounted() {
    this.listSystemTemplates();
    this.listTemplates();
    this.listUsers({ id: this.$auth.companyId });
  },
  methods: {
    ...mapActions({
      createTemplate: 'reportTemplate/create',
      listSystemTemplates: 'reportTemplate/listSystem',
      listTemplates: 'reportTemplate/list',
      deleteTemplate: 'reportTemplate/remove',
      listUsers: 'company/listUsers',
      createScheduledReport: 'scheduledReport/create'
    }),
    onSelect(item) {
      this.selectedTemplateId = item.id;
      this.showActionBox = true;
    },
    onClickSchedule() {
      this.modals.schedule.form = {
        ...initialScheduleForm(),
        reportTemplateId: this.selectedTemplateId
      };

      this.modals.schedule.show = true;
    },
    async onScheduleReport() {
      this.modals.schedule.loading = true;

      if (this.modals.schedule.form.overrides) {
        this.modals.schedule.form.overrides = Object.entries(this.modals.schedule.form.overrides).reduce((acc, [key, value]) => {
          if (value) {
            acc[key] = value;
          }

          return acc;
        }, {});
      }

      await this.createScheduledReport({
        data: this.modals.schedule.form
      });

      this.modals.schedule = {
        show: false,
        form: {},
        loading: false
      };
    },
    async onClickDuplicate() {
      // Check name doesn't already exist
      const existingNames = this.templates.map(t => t.name);

      const findName = (name, i = 1) => {
        const fullName = `${name} (${i})`;

        if (existingNames.includes(fullName)) {
          return findName(name, i + 1);
        }

        return fullName;
      };

      const newTemplate = await this.createTemplate({
        data: {
          ...this.selectedTemplate,
          _id: undefined,
          name: findName(this.selectedTemplate.name),
          access: 'user',
          userSub: this.$auth.user.sub
        }
      });

      this.listTemplates();

      this.selectedTemplateId = newTemplate._id;
    },
    onClickUseTemplate() {
      this.$router.push({
        name: this.selectedTemplate.type === 'dynamic' ? 'reports-dynamic' : 'reports-generate',
        query: {
          template: this.selectedTemplate._id
        }
      });
    },
    async onDeleteTemplate() {
      try {
        await this.deleteTemplate({
          id: this.selectedTemplate._id
        });

        this.$toasted.success('Template deleted successfully');
        this.listTemplates();

        this.deleteModal = false;
      } catch (e) {
        this.$toasted.error('Something went wrong');
      }

      this.selectedTemplateId = null;
    }
  }
};
</script>
